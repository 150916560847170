<template>
  <div class="page-content">
    <div class="barra-buscar">
        <div id="search-section" class="search-section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-9 p_l_z">
            <select v-model="tipo" id="tipo">
              <option value>Tipo</option>

              <option style="text-transform:uppercase" v-for="res in restipos" :key="res.id" v-bind:value="res.nombre">{{res.nombre}}</option>
            </select>
            <select v-model="ubicacion" id="ubicacion" @change="llenarColonias">
              <option value>Ubicación</option>
              <option style="text-transform:uppercase" v-for="res in resUbicaciones" :key="res.estado" v-bind:value="res.estado">
                {{res.estado.toUpperCase()}}
              </option>
            </select>
            <select v-model="colonia" id="colonia">
              <option value>Colonia</option>
              <option  style="text-transform:uppercase" v-for="col in resColonias" v-bind:value="col.colonia" :key="col.colonia" >
                  {{col.colonia.toUpperCase()}}
              </option>      
            </select>
            <select v-model="op" id="operacion">
              <option value>Operacion</option>
              <option value="Renta">Renta</option>
              <option value="Venta">Venta</option>
              <option value="Preventa">Preventa</option>
            </select>
            <select v-model="recamaras" id="recamaras">
              <option value>Recámaras</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
            </select>

            <select v-model="estacionamiento" id="estacionamiento">
              <option value>Estacionamiento</option>
              <option value="1">1 Lugar</option>
              <option value="2">2 Lugares</option>
              <option value="3">3 Lugares</option>
              <option value="4">4 Lugares</option>
              <option value="5">5 Lugares</option>
            </select>

            <select v-model="banos" id="banos">
              <option value>Baños</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <select v-model="medbanos" id="mbanos">
              <option value>1/2 Baños</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <input
              type="number"
              list="minprecios"
              v-model="minprecio"
              id="minprecio"
              step="10000"
              min="10000"
              placeholder="Precio Minimo"
            />

            <input
              type="number"
              list="maxprecios"
              v-model="maxprecio"
              id="maxprecio"
              step="10000"
              min="10000"
              placeholder="Precio Máximo"
            />
          </div>

          <div class="col-md-2 col-sm-3">
            <div class="section-header">
              <button
                title="search"
                class="btn-principal"
                @click="urlAction"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div id="property-listing" class="property-listing inicio-banner">
      <div class="container">
        <div class="row">
          <div class="property-left col-md-12 col-sm-6 p_l_z ">
            <div class="section-header p_l_z">
              <div class="col-md-10 col-sm-10 p_l_z">
                <p>Resultados</p>
                
              </div>
            </div>
            
            <div class="property-listing-row row">
              <!-- Col-md-4 -->
              <div class="col-md-4 col-sm-12 rent-block" v-for="prop in search" :key="prop">
                <!-- Property Main Box -->
                <div class="property-main-box">
                  <div class="property-images-box">
                    <span style="font-size: 10px; width: 95px; z-index: 80">
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                    </span>
                    <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: prop.url },
                            }"
                    >
                      <figure>
                        <img
                                :src="
                                  `https://fincabienesraices.com.mx/Admin/imagenes/` +
                                  prop.id +
                                  `/thumb-portada/` +
                                  prop.img_perfil
                                "
                                alt="Image"
                                data-class="img-fluid"
                                class="imagen-prp"
                              />
                      </figure>
                    </router-link>
                    <h4 style="font-size: 15px">${{formatoNumero(prop.precio)}} {{prop.moneda}}</h4>
                  </div>
                  <div class="clearfix"></div>
                  <div class="property-details">
                    <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: prop.url },
                            }"
                      >{{prop.titulo}}</router-link
                    >
                    <ul></ul>
                    <p  >{{isHtml(prop.descripcion)}}...</p>
                  </div>
                  <div class="propiedad-footer d-flex justify-content-between">
                    <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: prop.url },
                            }" class="verp" >Ver propiedad <ion-icon name="chevron-forward-outline"></ion-icon></router-link>
                    <a
                      target="_blank"
                      :href="`https://api.whatsapp.com/send?phone=+525584932033&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20propiedad%20`+prop.titulo"
                      class="wsp"
                      > Whatsapp</a
                    >
                    <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: prop.url },
                            }"  class="cnt">Contactar</router-link>
                  </div>
                </div>
                <!-- Property Main Box /- -->
              </div>

            </div>
            <h3 v-if="!nohay">¡Propiedades disponibles!</h3>
                <h3 v-if="nohay">¡Lo sentimos no tenemos más propiedades disponibles!</h3>
            <div v-if="search" v-observe-visibility="visibilityChanged">  
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <!-- Property Listing Section /- -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      search: [],
      tipo: "",
      restipos:[],
      resUbicaciones:[],
      resColonias:[],
      ubicacion: "",
      colonia: "",
      operacion: "",
      recamaras: "",
      estacionamiento: "",
      banos: "",
      op:"",
      page:1,
      medbanos: "",
      searchW:"",
      minprecio: "",
      maxprecio: "",
      nohay:false,
    };
  },
  mounted: function() {
    this.llenarbarrabuscadora();
    this.revisarUrl(this.$route.query);
    
  },
  
  methods: {
    isHtml(str){   
      const str2 =  str.replace(/(<([^>]+)>)/gi, "");
      
      return str2.charAt(0).toUpperCase() + str2.slice(1).substr(0,80).toLowerCase();
      
    },
    formatoNumero(numero) {
        var n = numero;
        n = String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");

        return n;
    },
    urlAction() { 
      
      this.$router.push({ path: "/buscar", query: { tipo: this.tipo , ubicacion: this.ubicacion,colonia : this.colonia,operacion: this.operacion,
        recamaras: this.recamaras, estacionamiento : this.estacionamiento, banos:this.banos, op:this.op, medbanos:this.medbanos,
        minprecio:this.minprecio, maxprecio:this.maxprecio
      } });
      this.search = [];
      this.page = 1;
      this.buscar();
    },
     visibilityChanged(isVisible) 
     {
       if(!isVisible){
        return
        }
        this.page++;
        this.buscar();
    },
    async buscar(){
      var getdatas = "https://fincabienesraices.mx/api/buscar?page="+this.page+"&tipo="+this.tipo
      +"&ubicacion="+this.ubicacion+"&colonia="+this.colonia+"&op="+this.op+"&recamaras="+this.recamaras
      +"&estacionamiento="+this.estacionamiento+"&banos="+this.banos+"&minprecio="+this.minprecio
      +"&max-precio="+this.maxprecio+"&search="+this.searchW;
    console.log(getdatas)
     await axios
      .get(getdatas)
      .then((response) => {
        if (response.data.error) {
          console.log("Error al consultar las propiedades en preventa",response.data.mensaje);
          this.nohay = true;
        } else {
          this.nohay = false;
          this.search.push(... response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
     async llenarColonias(){
         await fetch('https://fincabienesraices.com.mx/api/buscar-colonias', {
            method: 'POST',
            body: JSON.stringify({
              ciudad: this.ubicacion, 
            })
          }).then(response => response.json())
            .then(data =>{
              console.log(data);
              if(data.code == 200){
                this.resColonias = [];
                this.resColonias = data.res;
              }
            });
      },
    async llenarbarrabuscadora(){
      await axios.get('https://fincabienesraices.mx/api/resBarra')
      .then((response)=>{
        this.restipos = response.data.tipos;
        this.resUbicaciones = response.data.ubicaciones;
        this.resColonias = response.data.colonias;
      })
      .catch((error)=>{
        console.log('Error al conectar API restipos=>',error);
      });
    },
    revisarUrl(gets){
      if (typeof gets.tipo !== 'undefined') {
        this.tipo = gets.tipo
      }
      if (typeof gets.ubicacion !== 'undefined') {
        this.ubicacion = gets.ubicacion
      }
      if (typeof gets.colonia !== 'undefined') {
        this.colonia = gets.colonia
      }
      if (typeof gets.op !== 'undefined') {
        this.op = gets.op
      }
      if (typeof gets.recamaras !== 'undefined') {
        this.recamaras = gets.recamaras
      }
      if (typeof gets.estacionamiento !== 'undefined') {
        this.estacionamiento = gets.estacionamiento
      }
      if (typeof gets.banos !== 'undefined') {
        this.banos = gets.banos
      }
      if (typeof gets.minprecio !== 'undefined') {
        this.minprecio = gets.minprecio
      }
      if (typeof gets.maxprecio !== 'undefined') {
        this.maxprecio  = gets.maxprecio
      }
      if (typeof gets.maxprecio !== 'undefined') {
        this.searchW  = gets.search
      }
      this.buscar();
     
    }
  },
};
</script>

<style scoped> 
.page-content{
  overflow: unset;
}
.barra-buscar{
  position: sticky;
  top: 0;
  z-index: 99;
}
.barra-buscar .search-section{
  padding: 25px 0 0px 0;

}
.imagen-prp{
  height:240px ;
}
.property-main-box{
  height: auto;
}
.property-listing .propiedad-footer{
  border: 0;
}
.propiedad-footer a{
  text-decoration: none;
  display: flex;
  align-items: center;
}
.propiedad-footer .verp{
    width: 40%;
    background-color: #ea0c0c;
    color: white;
    font-size: 10pt;
    display: flex;
    justify-content: space-between;
    padding: 10px 18px;
}
.propiedad-footer .wsp{
  margin: unset;
  width: 30%;
  font-size: 10pt;
  border-radius: unset;
  justify-content: flex-end;
}
.propiedad-footer .cnt{
  font-size: 10pt;
  justify-content: flex-end;
  margin: unset;
  background-color: #0f0f0f;
  width: 30%;
  border-radius: unset;
}
.search-section .col-md-10 select,.search-section .col-md-10 input{
  padding:0;
  margin-bottom: 15px;
}
@media only screen and (min-width: 319px) and (max-width: 767px){
  .barra-buscar{
    position: relative;
  }
  .imagen-prp{
    height: 200px;
  }
  .property-main-box{
    width: 100%;
  }
  .search-section .col-md-10 select,.search-section .col-md-10 input{
    padding:15px 0;
    margin-bottom: 15px;
    width: 100%;
    margin-left: 0;
  }
}
</style>
