import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from "../views/Inicio.vue";
import Detalle from "../views/DetallePropiedad.vue";
import Buscar from "../views/buscar.vue";
import Test from "../views/test.vue";
import Login from '../views/Login.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Inicio,
  },
  {
    path: "/propiedad/:url",
    name: "Detalle",
    component: Detalle,
    props: true,
  },
  {
    path: "/buscar",
    name: "Buscar",
    component: Buscar,
  },
  {
    path: "/test",
    name: "test",
    component: Test,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      console.log("moving to top of the page");
      window.scrollTo(0, 0);
    }
 }
})

export default router
