<template>
<div>
  <a id="top"></a>

  <header class="nueva-top" v-bind:class="{active: isActive}">
    <div class="container d-flex justify-content-between">
      <div class="logo">
        <router-link
                            :to="{
                              name: 'Inicio',
                            }"
                          >
        <img
          src="https://fincabienesraices.com.mx/images/logo.png"
          alt="Logo Finca Bienes Raices"
        />
        </router-link>
      </div>
      <div class="menu-mob" @click="cambiarActive">
          <ion-icon class="abrir" name="menu-outline"></ion-icon>
          <ion-icon class="cerrar" name="close-outline"></ion-icon>
        </div>
      <nav class="navegador">
        <ul>
          <li>
            <router-link :to="{ name: 'Buscar' , query:{op:'Venta' } }"> Venta </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Buscar' , query:{op:'Renta' }}"> Renta </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Buscar', query:{op:'Preventa' } }"> Preventa </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Login' }"> {{logintxt  }} </router-link>
          </li>
          <li>
            <a target="_blank" href="https://www.facebook.com/fincabr/" class="redes-header"><ion-icon name="logo-facebook"></ion-icon></a>
            <a target="_blank" href="https://youtube.com/channel/UCvyHVn_Aw6oJL5nzWpVDi-Q" class="redes-header"><ion-icon name="logo-youtube"></ion-icon></a>
            <a target="_blank" href="https://instagram.com/fincabienesraices?igshid=1h08vcl32ts82" class="redes-header"><ion-icon name="logo-instagram"></ion-icon></a>
            <a class="redes-header"  @click="showBarra"><ion-icon name="search-outline"></ion-icon> </a>
          </li>
          <!--li>
            <router-link :to="{ name: 'Buscar', query:{op:'venta' } }"> Desarrollos </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Buscar', query:{op:'venta' } }"> Comercial </router-link>
          </li-->
        </ul>
        <div class="contador">
          <p>Total de propiedades: {{cantidad}}</p>
        </div>
        
      </nav>
    </div>
  </header>
  <div class="buscador" v-show="isBuscador">
      <div class="divB">
        <span class="cerrarbtn"  @click="showBarra"><ion-icon name="close-circle-outline"></ion-icon></span>
        <div class="inpB">
          <input type="text" v-model="palabraBuscar" @keyup.enter="buscarPorPalabra">
          <button @click="buscarPorPalabra" >Buscar</button>
        </div>
      </div>
     
  </div>
  </div>
</template>

<script>
import axios from 'axios'

export default ({
  data(){
    return{
      cantidad:239,
      isActive:false,
      logintxt:"Iniciar Sesión",
      isBuscador:false,
      palabraBuscar:""
    }
  },
  methods:{
     revisarSesion(){     
        if(localStorage.getItem("tokenSession_fi")){
            this.logintxt = localStorage.getItem("nombreBroker_fi");
        }
    },
    cambiarActive(){
       this.isActive = !this.isActive;
    },
    showBarra(){
      console.log("dmasidnjas");
      this.isBuscador = !this.isBuscador;
    },
    buscarPorPalabra(){
      this.isBuscador = false;
      this.$router.push({ path: "/buscar", query: { tipo: "" , ubicacion: "",colonia : "",operacion: "",
        recamaras: "", estacionamiento : "", banos:"", op:"", medbanos:"",
        minprecio:"", maxprecio:"", search:this.palabraBuscar } });
    },
    async cantidadProp(){
      await axios.get("https://fincabienesraices.com.mx/api/cantidad-prop")
      .then(res=>{
        this.cantidad = res.data.value
      })
      .catch(error=>{
        console.log("Error en la cantidad de propiedades" + error);
      })
    }
  },
  mounted(){
    this.revisarSesion();
    this.cantidadProp();
  }
})
</script>


<style>
.buscador{
  display: flex;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh; 
  justify-content: center;
  align-items: center;
  background-color: #3c3c44d4;
}
.buscador .divB{
  position: absolute;
}
.buscador .cerrarbtn{
  position: absolute;
  top: -36px;
  color: #fff;
  font-size: 25px;
  right: 0;
  cursor: pointer;
}
.buscador .inpB{
  display: flex;
}
.buscador input{
  width: 100%;
    border-radius: 15px 0px 0px 15px;
    border: none;
    height: 40px;
    padding: 0 20px;
    font-size: 19px;
}
.buscador button{
    border: none;
    height: 40px;
    padding: 0 30px;
    border-radius: 0 15px 15px 0;
    transition: all .2s ease-out;
}
.buscador button:hover{
    background-color: #ea0c0c;
    color: #e3e3e3;
}
header {
  padding: 10px 0;
}
header .logo img {
  width: 80px;
}
.navegador {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nueva-top .navegador ul {
  list-style: none;
  margin: 0;
  margin-right: 20px;
}
.nueva-top .navegador ul li {
  float: left;
  margin-left: 20px;
}
.nueva-top .navegador ul li a {
  padding: 0;
  font-size: 16px;
  font-family: "Karla", sans-serif;
  line-height: 38px;
  color: #3c3c44;
  text-decoration: none;
}
.nueva-top .contador {
  background-color: #ea0c0c;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 20px;
}
.nueva-top .contador p {
  margin: 0;
}
.nueva-top .navegador ul li a::before {
  content: " ";
  display: inline-block;
  margin-right: 18px;
  height: 8px;
  width: 8px;
  background-color: #e3e3e3;
  border-radius: 50%;
}
.nueva-top .navegador ul li a:hover{
  color: #ea0c0c;
}
.nueva-top .navegador ul li a.redes-header{
  cursor: pointer;
  margin: 0 8px;
}
.nueva-top .navegador ul li a.redes-header::before{
  content: unset;
}
.menu-mob{
  position: fixed;
  right: 10px;
  top: 10px;
  font-size: 44px;
  color: #3c3c44;
  display: none;
  z-index: 999;
}
.nueva-top .cerrar{
  display: none;
}
.nueva-top.active .abrir{
  display: none;
}
.nueva-top.active .cerrar{
  display: block;
}
</style>
